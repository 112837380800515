module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bznrecycling.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BZN Recycling","short_name":"BZN Recycling","description":"BZN Recycling is a leading provider of sustainable solutions for recycling automotive components such as headlights, \n        side mirrors, grilles, front radar modules and other electronic parts. We specialize in the responsible disposal of end-of-life vehicle parts, \n        ensuring that all components are reused or recycled in an environmentally friendly manner. Trust us to handle all your automotive recycling \n        needs and make a positive impact on the environment.","lang":"en","display":"browser","icon":"src/images/icon.png","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8c5d2d373cc0dcde9bd0b8d2865b34e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-120,"duration":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
